<template>
  <div class="column-flex">
    <div class="search-plate flex">
      <div class="flex">
        <span>选择题目：</span>
        <a-select :value="exam" style="width: 120px" @change="handleChange" placeholder="请选择">
          <a-select-option v-for="(item,index) in examOptions" :key="index" :value="item.id">
            {{item.title}}
          </a-select-option>
        </a-select>
      </div>
      <a-button @click="exportDetail(exam)" type="primary">
        导出点击详情
      </a-button>
    </div>
    <div class="container">
      <a-table :columns="columns" :row-key="(record) => record.uuid" :data-source="examList" :pagination="false"
        :loading="loading">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'title'">
            {{examInfo?.title}}
          </template>
          <template v-if="column.key === 'actionType'">
            {{record?.actionType?.title}}
          </template>
          <template v-else-if="column.key === 'action'">
            <span>
              <a @click="showModal(record)">答题详情</a>
            </span>
          </template>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="visible" @ok="handleOk" width="1400px">
      <template #title>
        <div ref="modalTitleRef" style="width: 100%; cursor: move">点击详情</div>
      </template>
      <a-table :columns="datailColumns" :row-key="(record) => record.uuid" :data-source="detailList"
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'title'">
            {{record?.attributes?.exam?.data?.attributes?.title}}
          </template>
          <template v-if="column.key === 'level'">
            {{getLevel(record?.attributes?.question?.data?.attributes?.level)}}
          </template>
          <template v-if="column.key === 'actionTime'">
            {{getTime(record?.attributes?.actionTime)}}
          </template>
          <template v-if="column.key === 'titleLevel'">
            {{getTitleName(record?.attributes?.doctor?.data?.attributes?.titleLevel)}}
          </template>
          <template v-if="column.key === 'hospital'">
            {{record?.attributes?.doctor?.data?.attributes?.hospital}}
          </template>
          <template v-if="column.key === 'phone'">
            {{maskPhoneNumber(record?.attributes?.doctor?.data?.attributes?.phone)}}
          </template>
          <template v-if="column.key === 'actionType'">
            {{detailInfo?.actionType?.title}}
          </template>
          <template v-if="column.key === 'actionValue'">
            <!-- 性别 -->
            <template v-if="actionValue=='gender'">
              {{record?.attributes?.question?.data?.attributes[actionValue]
              ==1?'男':record?.attributes?.question?.data?.attributes[actionValue]==0?'女':''}}
            </template>
            <!-- 抵达方式 -->
            <template v-else-if="actionValue=='arrivalTransport'">
              {{getArrivalTransport(record?.attributes?.question?.data?.attributes[actionValue])}}
            </template>
            <!-- 体温（℃） -->
            <template v-else-if="actionValue=='temperature'">
              {{fahrenheitToCelsius(record?.attributes?.question?.data?.attributes[actionValue])}}
            </template>
            <!-- 平均动脉压（mmHg） -->
            <template v-else-if="actionValue=='map'">
              {{Number(record?.attributes?.question?.data?.attributes[actionValue]).toFixed(2)}}
            </template>
            <!-- 休克指数 -->
            <template v-else-if="actionValue=='shockIndex'">
              {{Number(record?.attributes?.question?.data?.attributes[actionValue]).toFixed(2)}}
            </template>
            <!-- 急诊分诊时刻 -->
            <template v-else-if="actionValue=='edHour'">
              {{record?.attributes?.question?.data?.attributes[actionValue]}}点
            </template>
            <!-- 其他 -->
            <template v-else>
              {{record?.attributes?.question?.data?.attributes[actionValue]}}
            </template>
          </template>
          <!-- 患者相关信息 -->
          <template v-if="column.key==='relatedInfo'">
            <span v-show="false">{{record.showDetail}}</span>
            <a @mouseover="changeDetail(record,true)" @mouseleave="changeDetail(record,false)">查看</a>
            <div v-if="record.showDetail" class="detail-form">
              <a-form :model="record?.attributes?.question?.data?.attributes" :label-wrap="true" label-align='left'>
                <a-form-item label="急诊分诊时刻（小时）" name="edHour">
                  <a-input :value="record?.attributes?.question?.data?.attributes?.edHour+'点'" />
                </a-form-item>
                <a-form-item label="年龄" name="age">
                  <a-input :value="record?.attributes?.question?.data?.attributes?.age" />
                </a-form-item>
                <a-form-item label="性别" name="gender">
                  <a-input
                    :value="record?.attributes?.question?.data?.attributes?.gender==1?'男':record?.attributes?.question?.data?.attributes?.gender==0?'女':''" />
                </a-form-item>
                <a-form-item label="抵达方式" name="arrivalTransport">
                  <a-input
                    :value="getArrivalTransport(record?.attributes?.question?.data?.attributes?.arrivalTransport)" />
                </a-form-item>
                <a-form-item label="体温（℃）" name="temperature">
                  <a-input :value="fahrenheitToCelsius(record?.attributes?.question?.data?.attributes?.temperature)" />
                </a-form-item>
                <a-form-item label="心率（bpm）" name="heartrate">
                  <a-input :value="record?.attributes?.question?.data?.attributes?.heartrate" />
                </a-form-item>
                <a-form-item label="呼吸率（次/分）" name="resprate">
                  <a-input :value="record?.attributes?.question?.data?.attributes?.resprate" />
                </a-form-item>
                <a-form-item label="血氧饱和度" name="o2sat">
                  <a-input :value="record?.attributes?.question?.data?.attributes?.o2sat" />
                </a-form-item>
                <a-form-item label="收缩压（mmHg）" name="sbp">
                  <a-input :value="record?.attributes?.question?.data?.attributes?.sbp" />
                </a-form-item>
                <a-form-item label="舒张压（mmHg）" name="dbp">
                  <a-input :value="record?.attributes?.question?.data?.attributes?.dbp" />
                </a-form-item>
                <a-form-item label="平均动脉压（mmHg）" name="map">
                  <a-input :value="Number(record?.attributes?.question?.data?.attributes?.map).toFixed(2)" />
                </a-form-item>
                <a-form-item label="休克指数" name="shockIndex">
                  <a-input :value="Number(record?.attributes?.question?.data?.attributes?.shockIndex).toFixed(2)" />
                </a-form-item>
                <a-form-item label="ESI评级" name="acuity">
                  <a-input :value="record?.attributes?.question?.data?.attributes?.acuity" />
                </a-form-item>
              </a-form>
            </div>
          </template>
        </template>
      </a-table>
      <template #footer>
        <a-button key="back" @click="handleOk">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted } from 'vue'
  import { getList, eventsStatistics, eventsStatisticsDetail, exportEvent } from '@/api/answer'
  import { Modal } from 'ant-design-vue';

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
    },
    {
      title: '题目',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: '特征',
      dataIndex: 'actionType',
      key: 'actionType'
    },
    {
      title: '点击数量',
      dataIndex: 'count',
      key: 'count'
    },
    {
      title: '操作',
      key: 'action',
    },
  ]
  const datailColumns = [
    {
      title: '序号',
      dataIndex: 'index',
    },
    {
      title: '题目',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '题目（患者信息）',
      dataIndex: 'relatedInfo',
      key: 'relatedInfo',
    },
    {
      title: '题目难度',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: '特征',
      dataIndex: 'actionType',
      key: 'actionType',
    },
    {
      title: '特征值',
      dataIndex: 'actionValue',
      key: 'actionValue',
    },
    {
      title: '点击人员',
      key: 'phone',
      dataIndex: 'phone',

    },
    {
      title: '医院名称',
      dataIndex: 'hospital',
      key: 'hospital',
    },
    {
      title: '职称',
      dataIndex: 'titleLevel',
      key: 'titleLevel',
    },
    {
      title: '点击时间',
      dataIndex: 'actionTime',
      key: 'actionTime',
    },
  ]

  const formState = reactive({})
  const exam = ref(null)
  const examOptions = ref([])
  const examList = ref([])
  const examInfo = ref({})
  const detailInfo = ref({})
  const actionValue = ref('')
  const visible = ref(false)

  function getActionValue(value) {
    if (value) {
      const dotIndex = value.indexOf('.') + 1;
      const subStr = value.substring(dotIndex);

      return subStr
    }
  }

  function maskPhoneNumber(phoneNumber) {
    if (phoneNumber) {
      const maskedNumber = phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
      return maskedNumber;
    } else {
      return ''
    }
  }

  const getTime = (time) => {
    if (time) {
      const convertedTime = new Date(time);
      convertedTime.setHours(convertedTime.getHours() + 8);
      const formattedTime = convertedTime.toLocaleString('zh-CN', { timeZone: 'UTC' });
      return formattedTime;
    } else {
      return ''
    }
  }

  // 华氏度转摄氏度
  function fahrenheitToCelsius(fahrenheit) {
    if (fahrenheit > 0) {
      return parseInt((fahrenheit - 32) * 5 / 9) * 100 / 100 + '°C'
    } else {
      return ''
    }
  }

  // 救护车为0，1 直升机 ，2其他  自行为3
  const getArrivalTransport = (e) => {
    switch (e) {
      case 0:
        return '救护车';
        break;
      case 1:
        return '直升机';
        break;
      case 2:
        return '其他';
        break;
      case 3:
        return '自行';
        break;
      default:
        return '';
    }
  }

  const getTitleName = (level) => {
    switch (level) {
      case 1:
        return '医学生&住院医';
        break;
      case 2:
        return '主治医';
        break;
      case 3:
        return '副高级';
        break;
      case 4:
        return '正高级';
        break;
      default:
        return '';
    }
  }

  //1-10行 有风险且确信度高、 11-20行 有风险但确信度低、21-30行 无风险且确信度高、31-40行 无风险但确信度低
  const getLevel = (level) => {
    switch (level) {
      case 1:
        return '有风险且确信度高';
        break;
      case 2:
        return '有风险但确信度低';
        break;
      case 3:
        return '无风险且确信度高';
        break;
      case 4:
        return '无风险但确信度低';
        break;
      default:
        return '';
    }
  }

  const exportDetail = (id) => {
    exportEvent(id).then((res) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = res.file.url;
      // downloadLink.download = getEaxmName(id) + '点击详情.csv';
      downloadLink.download = '急诊分诊24小时内脓毒症风险预警-特征点击量情况-' + getCurrentDay() + '.csv'
      downloadLink.style.display = 'none';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
  }

  const getCurrentDay = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
  }

  function getEaxmName(id) {
    const result = examOptions.value.find(item => item.id == id);
    return result && result.attributes && result.attributes.title;
  }

  function changeDetail(record, type) {
    record.showDetail = type
  }

  const handleChange = (e) => {
    exam.value = e
    getDataList();
  }

  const showModal = (record) => {
    detailInfo.value = record
    actionValue.value = getActionValue(record.actionType.value)
    getStatisticsDetail()
    visible.value = true;
  };

  function getStatisticsDetail() {
    eventsStatisticsDetail({
      exam: exam.value,
      actionType: detailInfo.value.actionType.value,
      _pageSize: pagination.pageSize,
      _page: pagination.current,
    }).then((res) => {
      res.data.forEach((item, index) => {
        item.index = (pagination.current - 1) * pagination.pageSize + index + 1;
        item.showDetail = false;
      });
      detailList.value = res.data
      pagination.total = res.meta.pagination.total
    })
  }

  const handleOk = (e) => {
    visible.value = false;
  };

  const detailList = ref([])
  const pagination = reactive({
    showLessItems: true,
    showQuickJumper: true,
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0
  })
  const loading = ref(false)

  function handleTableChange(p) {
    pagination.current = p.current
    pagination.pageSize = p.pageSize
    getStatisticsDetail()
  }

  function fetch(page) {
    getList({
      _pageSize: 100,
      _page: page,
    }).then((res) => {
      res.data.forEach((item, index) => {
        for (const key in item.attributes) {
          item[key] = item.attributes[key];
        }
      });
      examOptions.value = examOptions.value.concat(res.data)

      if (res.meta.pagination.total > (100 * page)) {
        page++
        fetch(page)
      } else {
        exam.value = res.data[0].id
        getDataList();
      }
    })
  }

  function getDataList() {
    eventsStatistics({
      exam: exam.value,
    }).then((res) => {
      res.data.attributes.actions.forEach((item, index) => {
        item.index = index + 1
      });
      examInfo.value = res.data.attributes
      examList.value = res.data.attributes.actions
    })
  }

  onMounted(() => {
    fetch(1);
  })
</script>
<style lang="less" scoped>
  .column-flex {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    .container {
      flex: 1;
    }
  }

  .search-plate {
    background: #fff;
    padding: 25px 30px;
    margin-bottom: 15px;
    border-radius: 5px;

    .ant-btn {
      margin-left: 10px;
    }

    .ant-btn-primary {
      background: #216AEC;
    }

    :deep .ant-select {
      width: 230px !important;
      margin-right: 10px;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }

  .ant-form {
    display: flex;
    flex-flow: row wrap;

    :deep .ant-form-item {
      display: flex;
      flex-flow: column nowrap;
      margin: 0 35px 12px 0;
      height: 64px;

      .ant-input {
        border-color: #EBEDF0;
      }
    }

  }

  :deep .ant-form-item-label>label {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: rgba(77, 96, 134, 0.85);
  }

  .detail-form {
    position: fixed;
    left: calc((100vw - 1200px) / 2 + 240px);
    top: calc(32vh);
    width: 800px;
    height: 405px;
    padding: 13px 25px 25px;
    border-radius: 5px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    z-index: 9;
  }
</style>